(function() {
    'use strict';

    angular.module('adminApp')
        .controller("ViewUserLoggingCtrl", viewUserLoggingController);

    viewUserLoggingController.$inject = ["$scope", "aerosAdminApi", "$stateParams", "$uibModal"];

    function viewUserLoggingController($scope, aerosAdminApi, $stateParams, $uibModal) {

        var currentOffset = 0;
        $scope.data = "";

        loadCurrentPage();

        function loadCurrentPage() {
            aerosAdminApi.loadOrganizationUserLogs($stateParams.orgId,$stateParams.userEmail,10,currentOffset).success(function (data) {
                if (data.length) {
                    $scope.data = data;
                };
            });
        }

        $scope.getNextPage = function () {
            currentOffset += 10;
            loadCurrentPage();
        }

        $scope.getPreviousPage = function () {
            currentOffset -= 10;
            if( currentOffset < 0 )
                currentOffset = 0;
            loadCurrentPage();
        }

        $scope.inspectJSON = function (elementJson) {
            var jsonBody = JSON.stringify(elementJson,null,4);
            if (jsonBody.length) {
                $uibModal.open({
                    size: "md",
                    templateUrl: 'jsonLogDetailsModal.html',
                    controller: ["$scope", "jsonBody",
                        function ($scope, jsonBody) {
                            angular.extend($scope, {
                                jsonBody: jsonBody
                            });
                        }],
                    resolve: {
                        jsonBody: function () {
                            return jsonBody;
                        }

                    }
                });
            };
        };
    }

})();


